<template>
  <v-card :flat="flat">
    <v-card-title>
      <div>
        <div class="bold-text">Leave team</div>
        <div class="smallFont">Are you sure you want to leave this team?</div>
      </div>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-alert class="mt-3" border="left" color="info" icon="info" outlined>
        You will lose access to any information regarding this team.
        <div v-if="readMore">
          <br />
          This team will be deleted and everything attached to it
          <b>if you are the only one inside it.</b><br />
          <br />

          <ul>
            <li>Apps</li>
            <ul>
              <li>Chat</li>
              <li>Analytics</li>
              <li>App Tokens</li>
              <li>Releases & Comments</li>
              <li>Sessions & Feedback</li>
              <li>Public install links</li>
            </ul>
            <li>Invites</li>
            <li>Timeline</li>
            <li>Members</li>
            <li>API Tokens</li>
            <li>Integrations</li>
            <li>Notifications related this team</li>
          </ul>

          <br />
          However, your apps and releases data will still be stored in the team,
          but you won't have access to it.
          <br />
          <br />
          If someone invites you back to the team, you can regain access.
        </div>
        <b class="pointer" @click="readMore = !readMore" v-if="!readMore">
          {{ "Read more..." }}
        </b>
        <br />
      </v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions class="py-3">
      <div>No undo for this action</div>
      <v-spacer />
      <v-btn class="text-transform-none" text color="primary" @click="onCancel"
        >No</v-btn
      >
      <v-btn
        class="text-transform-none"
        color="primary"
        :outlined="$vuetify.theme.dark"
        @click="onConfirm"
        :loading="busy"
        >Yes</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import { LEAVE_TEAM } from "@/store/team/manageTeam.module";
import {
  CLEAR_STORE,
  REMOVE_IDENTITY_FOR_TRACKER,
} from "@/store/users/auth.module";
import { mapMutations } from "vuex";

export default {
  name: "LeaveTeamModal",
  props: {
    flat: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      readMore: false,
      busy: false,
    };
  },
  methods: {
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
      updateTeamId: "updateTeamId",
    }),
    onConfirm() {
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(LEAVE_TEAM)
        .then((response) => {
          this.busy = false;
          this.notifyUserMessage({
            message: response.data.message || "Successfully left the Team",
          });
          this.$emit("close");
          this.$store.dispatch(CLEAR_STORE);
          this.$store.commit(REMOVE_IDENTITY_FOR_TRACKER);
          this.updateUserPermission({});
          this.updateTeamId(null);
          this.$nextTick(() => {
            this.$router.push({ name: "welcome" }).catch(() => {});
          });
        })
        .catch((err) => {
          this.busy = false;
          this.$emit("close");
          this.notifyErrorMessage(
            err.message || "Something went wrong, please try again"
          );
        });
    },
    onCancel() {
      this.$emit("close");
    },
  },
};
</script>
