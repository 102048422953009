<template>
  <v-card class="mt-15" flat>
    <v-row>
      <v-col cols="12" md="4">
        <v-card color="info" class="max-height-container">
          <v-img src="/media/instruction/kyc_img.png">
            <v-row align="center" justify="center">
              <v-col cols="12" class="titleFont mt-8 text-center">
                <div>👋</div>
                <div class="offWhite--text">Welcome, {{ user.name }}!</div>
              </v-col>
              <v-col class="text-center px-8 bodyFont offWhite--text">
                We're excited to have you here.<br /><br />By answering these
                questions (as many as you like), we can customize your
                experience better. <br /><br />Feel free to skip any question.
              </v-col>
            </v-row>
          </v-img>
        </v-card>
      </v-col>
      <v-col class="mt-8" cols="12" md="8">
        <v-row>
          <v-col class="mt-2" cols="12" md="12">
            <v-row>
              <v-col class="py-0 pr-7 text-right" cols="12">
                <b>{{ step }}</b> / <b>{{ questionare.length }}</b> Questions
              </v-col>
            </v-row>
            <v-row v-for="(item, index) in questionare" :key="index">
              <v-col v-if="step == index + 1" class="mb-10" cols="12">
                <v-card :id="item.id" flat>
                  <v-col class="py-0" cols="12">
                    <v-row align="center">
                      <v-col
                        :class="
                          $vuetify.breakpoint.mdAndUp ? '' : 'text-center'
                        "
                        class="pr-0"
                        cols="12"
                        md="auto"
                      >
                        <v-avatar
                          :color="$vuetify.theme.dark ? '' : 'primary'"
                          size="40"
                        >
                          <v-icon color="white" v-text="item.icon"></v-icon>
                        </v-avatar>
                      </v-col>
                      <v-col cols="12" md="auto">
                        <span class="subListFont ml-3">
                          {{ item.question }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="py-0" cols="12">
                    <v-card
                      :class="$vuetify.breakpoint.mdAndUp ? 'ml-15' : ''"
                      flat
                    >
                      <v-card-text>
                        <div v-if="item.meta.type == 'dropdown'">
                          <v-select
                            v-model="item.user_answer"
                            v-validate="''"
                            :disabled="step < index + 1"
                            :items="item.answers"
                            :label="item.label"
                            :placeholder="item.placeholder"
                            filled
                            v-bind="veeValidate('', '')"
                          ></v-select>
                          <v-text-field
                            v-if="
                              item.user_answer == 'Other' ||
                              item.user_answer.includes('Other')
                            "
                            v-model="item.other_answer"
                            v-validate="'required|max:100'"
                            class="mt-4"
                            counter="100"
                            filled
                            label="Type your answer here..."
                            v-bind="veeValidate('Answer', '')"
                          ></v-text-field>
                        </div>

                        <div v-if="item.meta && item.meta.type == 'checkbox'">
                          <v-checkbox
                            v-for="(option, cindex) in item.answers"
                            :key="cindex"
                            v-model="item.user_answer"
                            :label="option"
                            :value="option"
                            hide-details
                          ></v-checkbox>
                          <v-text-field
                            v-if="item.user_answer.includes('Other')"
                            v-model="item.other_answer"
                            v-validate="'required|max:100'"
                            class="mt-4"
                            counter="100"
                            filled
                            label="Type your answer here..."
                            v-bind="veeValidate('Answer', '')"
                          ></v-text-field>
                        </div>

                        <div v-if="item.meta && item.meta.type == 'radio'">
                          <v-radio-group v-model="item.user_answer" mandatory>
                            <template v-for="(option, cindex) in item.answers">
                              <v-radio
                                :id="option"
                                :key="cindex"
                                :disabled="busy"
                                :value="option"
                              >
                                <template v-slot:label>
                                  <div>
                                    <span class="mr-2">{{ option }}</span>
                                  </div>
                                </template>
                              </v-radio>
                            </template>
                          </v-radio-group>

                          <v-text-field
                            v-if="item.user_answer.includes('Other')"
                            v-model="item.other_answer"
                            v-validate="'required|max:100'"
                            class="mt-4"
                            counter="100"
                            filled
                            label="Type your answer here..."
                            v-bind="veeValidate('Answer', '')"
                          ></v-text-field>
                        </div>
                      </v-card-text>
                      <v-card-actions class="pl-20 pt-20">
                        <v-btn
                          v-if="step > 1"
                          :disabled="busy"
                          class="text-transform-none"
                          color="primary"
                          depressed
                          outlined
                          @click="goToPreviousStep(item.id)"
                        >
                          Previous
                        </v-btn>
                        <v-btn
                          :disabled="errors.any()"
                          :loading="busy"
                          class="text-transform-none"
                          color="primary"
                          :outlined="$vuetify.theme.dark"
                          depressed
                          @click="updateAnswers(item, index + 2)"
                        >
                          <div v-if="step > index + 1" class="font14">
                            Update
                          </div>
                          <div v-else class="font14">
                            {{ step >= questionare.length ? "Finish" : "Next" }}
                          </div>
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
// import VideoModal from "@/view/components/Common/VideoModal";
import veeValidate from "@/mixins/veeValidate";
import { GET_KYC_LIST, UPDATE_KYC_QUESTION } from "@/store/users/kyc.module";
import { GET_USER_ROLES } from "@/store/users/auth.module";

export default {
  components: {},
  mixins: [veeValidate],
  data() {
    return {
      formValues: {},
      step: 1,
      busy: false,
      questionare: [],
    };
  },
  computed: {
    ...mapGetters({
      user: "currentUser",
      getTeamId: "getTeamId",
    }),
  },
  created() {
    this.getQuestionList();
  },
  methods: {
    getQuestionList() {
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_KYC_LIST)
        .then((response) => {
          this.loading = false;
          this.questionare = response.data;
          if (response?.data[0]?.user_answer.length) {
            this.$emit("close");
            return;
          }
          this.questionare.forEach((item) => {
            if (item.meta && !item.user_answer.length && item.meta.default) {
              item.user_answer = item.meta.default;
            }

            if (
              item.meta &&
              (item.meta.type == "dropdown" || item.meta.type == "radio")
            ) {
              item.user_answer = item.user_answer.length
                ? item.user_answer[0]
                : [];
            }
          });
        })
        .catch((err) => {
          this.loading = false;
          this.notifyErrorMessage({ message: err.message });
        });
    },
    async updateAnswers(item, step) {
      if (await this.validateAllFields()) {
        if (item.user_answer && !Array.isArray(item.user_answer)) {
          item.user_answer = [item.user_answer];
        }
        if (this.busy) return;
        this.busy = true;
        const dataToPost = {
          id: item.id,
          answer: item.user_answer,
          other_answer: item.other_answer,
        };
        this.$store
          .dispatch(UPDATE_KYC_QUESTION, dataToPost)
          .then(() => {
            if (
              (item.meta.type == "dropdown" || item.meta.type == "radio") &&
              item.user_answer.length
            ) {
              item.user_answer = dataToPost.answer[0];
            }
            this.goToNextStep(step);
          })
          .catch((err) => {
            this.busy = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    goToNextStep(step) {
      this.step = step;
      if (step > this.questionare.length) {
        this.$store.dispatch(GET_USER_ROLES).then(() => {
          const isPartOfTeam = this.getTeamId;
          if (isPartOfTeam) {
            this.$router.push({ name: "dashboard" });
          } else {
            this.$router.push({ name: "welcome" });
          }
          this.busy = false;
        });
      } else {
        this.busy = false;
      }
    },
    goToPreviousStep() {
      this.step = this.step - 1;
    },
  },
};
</script>

<style lang="scss">
.max-height-container {
  max-height: 50vh;
  overflow: hidden;
}
</style>
