var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-card-title', [_c('v-spacer'), _c('h2', {
    staticClass: "blue-text text-center font-weight-bold"
  }, [_vm._v(" You've been invited! ")]), _c('v-spacer')], 1), _vm.busy ? [_c('ContentLoader')] : !_vm.busy && !_vm.currentInvite ? [_c('v-card-text', [_c('vue-inline-alert', {
    attrs: {
      "messageType": "error",
      "message": _vm.message,
      "no-close-btn": ""
    }
  })], 1)] : [_c('v-card-text', {
    staticClass: "text-center"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-center"
  }, [_c('h3', {
    staticClass: "mt-8"
  }, [_vm._v(" " + _vm._s(_vm.currentInvite && _vm.currentInvite.team ? _vm.currentInvite.team.name : "") + " ")]), _vm.currentInvite ? _c('div', {
    staticClass: "mt-4 ml-4"
  }, [_c('p', {
    staticClass: "bodyFont"
  }, [_vm._v(" " + _vm._s("".concat(_vm.currentInvite.from_user.name, " invited you to join ").concat(_vm.currentInvite.team.name)) + " ")])]) : _vm._e(), _vm.currentInvite && _vm.currentInvite.message ? _c('div', {
    staticClass: "bodyFont"
  }, [_vm._v(" With message: ")]) : _vm._e(), _c('p', {
    staticClass: "bodyFont"
  }, [_vm._v(" " + _vm._s(_vm.currentInvite.message) + " ")]), _c('p', {
    staticClass: "text-muted bodyFont"
  }, [_c('vue-hoverable-date', {
    attrs: {
      "date": _vm.currentInvite.created_at
    }
  })], 1)])], 1)], 1), _c('v-card-actions', {
    staticClass: "pb-1 mx-2"
  }, [_c('v-row', [_c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "large main fill-width",
    attrs: {
      "loading": _vm.loading2,
      "disabled": _vm.loading || _vm.loading2,
      "outlined": _vm.$vuetify.theme.dark,
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.submitAction('accept');
      }
    }
  }, [_vm._v(" accept ")])], 1), _c('v-col', {
    staticClass: "pb-0",
    attrs: {
      "cols": "6"
    }
  }, [_c('v-btn', {
    staticClass: "large main fill-width",
    attrs: {
      "outlined": "",
      "color": "error",
      "loading": _vm.loading,
      "disabled": _vm.loading || _vm.loading2
    },
    on: {
      "click": function click($event) {
        return _vm.submitAction('decline');
      }
    }
  }, [_vm._v(" decline ")])], 1)], 1)], 1)]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }