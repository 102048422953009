var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mt-15",
    attrs: {
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('v-card', {
    staticClass: "max-height-container",
    attrs: {
      "color": "info"
    }
  }, [_c('v-img', {
    attrs: {
      "src": "/media/instruction/kyc_img.png"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "titleFont mt-8 text-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', [_vm._v("👋")]), _c('div', {
    staticClass: "offWhite--text"
  }, [_vm._v("Welcome, " + _vm._s(_vm.user.name) + "!")])]), _c('v-col', {
    staticClass: "text-center px-8 bodyFont offWhite--text"
  }, [_vm._v(" We're excited to have you here."), _c('br'), _c('br'), _vm._v("By answering these questions (as many as you like), we can customize your experience better. "), _c('br'), _c('br'), _vm._v("Feel free to skip any question. ")])], 1)], 1)], 1)], 1), _c('v-col', {
    staticClass: "mt-8",
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "mt-2",
    attrs: {
      "cols": "12",
      "md": "12"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "py-0 pr-7 text-right",
    attrs: {
      "cols": "12"
    }
  }, [_c('b', [_vm._v(_vm._s(_vm.step))]), _vm._v(" / "), _c('b', [_vm._v(_vm._s(_vm.questionare.length))]), _vm._v(" Questions ")])], 1), _vm._l(_vm.questionare, function (item, index) {
    return _c('v-row', {
      key: index
    }, [_vm.step == index + 1 ? _c('v-col', {
      staticClass: "mb-10",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      attrs: {
        "id": item.id,
        "flat": ""
      }
    }, [_c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-row', {
      attrs: {
        "align": "center"
      }
    }, [_c('v-col', {
      staticClass: "pr-0",
      class: _vm.$vuetify.breakpoint.mdAndUp ? '' : 'text-center',
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('v-avatar', {
      attrs: {
        "color": _vm.$vuetify.theme.dark ? '' : 'primary',
        "size": "40"
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "white"
      },
      domProps: {
        "textContent": _vm._s(item.icon)
      }
    })], 1)], 1), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "auto"
      }
    }, [_c('span', {
      staticClass: "subListFont ml-3"
    }, [_vm._v(" " + _vm._s(item.question) + " ")])])], 1)], 1), _c('v-col', {
      staticClass: "py-0",
      attrs: {
        "cols": "12"
      }
    }, [_c('v-card', {
      class: _vm.$vuetify.breakpoint.mdAndUp ? 'ml-15' : '',
      attrs: {
        "flat": ""
      }
    }, [_c('v-card-text', [item.meta.type == 'dropdown' ? _c('div', [_c('v-select', _vm._b({
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: '',
        expression: "''"
      }],
      attrs: {
        "disabled": _vm.step < index + 1,
        "items": item.answers,
        "label": item.label,
        "placeholder": item.placeholder,
        "filled": ""
      },
      model: {
        value: item.user_answer,
        callback: function callback($$v) {
          _vm.$set(item, "user_answer", $$v);
        },
        expression: "item.user_answer"
      }
    }, 'v-select', _vm.veeValidate('', ''), false)), item.user_answer == 'Other' || item.user_answer.includes('Other') ? _c('v-text-field', _vm._b({
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required|max:100',
        expression: "'required|max:100'"
      }],
      staticClass: "mt-4",
      attrs: {
        "counter": "100",
        "filled": "",
        "label": "Type your answer here..."
      },
      model: {
        value: item.other_answer,
        callback: function callback($$v) {
          _vm.$set(item, "other_answer", $$v);
        },
        expression: "item.other_answer"
      }
    }, 'v-text-field', _vm.veeValidate('Answer', ''), false)) : _vm._e()], 1) : _vm._e(), item.meta && item.meta.type == 'checkbox' ? _c('div', [_vm._l(item.answers, function (option, cindex) {
      return _c('v-checkbox', {
        key: cindex,
        attrs: {
          "label": option,
          "value": option,
          "hide-details": ""
        },
        model: {
          value: item.user_answer,
          callback: function callback($$v) {
            _vm.$set(item, "user_answer", $$v);
          },
          expression: "item.user_answer"
        }
      });
    }), item.user_answer.includes('Other') ? _c('v-text-field', _vm._b({
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required|max:100',
        expression: "'required|max:100'"
      }],
      staticClass: "mt-4",
      attrs: {
        "counter": "100",
        "filled": "",
        "label": "Type your answer here..."
      },
      model: {
        value: item.other_answer,
        callback: function callback($$v) {
          _vm.$set(item, "other_answer", $$v);
        },
        expression: "item.other_answer"
      }
    }, 'v-text-field', _vm.veeValidate('Answer', ''), false)) : _vm._e()], 2) : _vm._e(), item.meta && item.meta.type == 'radio' ? _c('div', [_c('v-radio-group', {
      attrs: {
        "mandatory": ""
      },
      model: {
        value: item.user_answer,
        callback: function callback($$v) {
          _vm.$set(item, "user_answer", $$v);
        },
        expression: "item.user_answer"
      }
    }, [_vm._l(item.answers, function (option, cindex) {
      return [_c('v-radio', {
        key: cindex,
        attrs: {
          "id": option,
          "disabled": _vm.busy,
          "value": option
        },
        scopedSlots: _vm._u([{
          key: "label",
          fn: function fn() {
            return [_c('div', [_c('span', {
              staticClass: "mr-2"
            }, [_vm._v(_vm._s(option))])])];
          },
          proxy: true
        }], null, true)
      })];
    })], 2), item.user_answer.includes('Other') ? _c('v-text-field', _vm._b({
      directives: [{
        name: "validate",
        rawName: "v-validate",
        value: 'required|max:100',
        expression: "'required|max:100'"
      }],
      staticClass: "mt-4",
      attrs: {
        "counter": "100",
        "filled": "",
        "label": "Type your answer here..."
      },
      model: {
        value: item.other_answer,
        callback: function callback($$v) {
          _vm.$set(item, "other_answer", $$v);
        },
        expression: "item.other_answer"
      }
    }, 'v-text-field', _vm.veeValidate('Answer', ''), false)) : _vm._e()], 1) : _vm._e()]), _c('v-card-actions', {
      staticClass: "pl-20 pt-20"
    }, [_vm.step > 1 ? _c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "disabled": _vm.busy,
        "color": "primary",
        "depressed": "",
        "outlined": ""
      },
      on: {
        "click": function click($event) {
          return _vm.goToPreviousStep(item.id);
        }
      }
    }, [_vm._v(" Previous ")]) : _vm._e(), _c('v-btn', {
      staticClass: "text-transform-none",
      attrs: {
        "disabled": _vm.errors.any(),
        "loading": _vm.busy,
        "color": "primary",
        "outlined": _vm.$vuetify.theme.dark,
        "depressed": ""
      },
      on: {
        "click": function click($event) {
          return _vm.updateAnswers(item, index + 2);
        }
      }
    }, [_vm.step > index + 1 ? _c('div', {
      staticClass: "font14"
    }, [_vm._v(" Update ")]) : _c('div', {
      staticClass: "font14"
    }, [_vm._v(" " + _vm._s(_vm.step >= _vm.questionare.length ? "Finish" : "Next") + " ")])])], 1)], 1)], 1)], 1)], 1) : _vm._e()], 1);
  })], 2)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }