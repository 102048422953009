var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": _vm.flat
    }
  }, [_c('v-card-title', [_c('div', [_c('div', {
    staticClass: "bold-text"
  }, [_vm._v("Leave team")]), _c('div', {
    staticClass: "smallFont"
  }, [_vm._v("Are you sure you want to leave this team?")])])]), _c('v-divider'), _c('v-card-text', [_c('v-alert', {
    staticClass: "mt-3",
    attrs: {
      "border": "left",
      "color": "info",
      "icon": "info",
      "outlined": ""
    }
  }, [_vm._v(" You will lose access to any information regarding this team. "), _vm.readMore ? _c('div', [_c('br'), _vm._v(" This team will be deleted and everything attached to it "), _c('b', [_vm._v("if you are the only one inside it.")]), _c('br'), _c('br'), _c('ul', [_c('li', [_vm._v("Apps")]), _c('ul', [_c('li', [_vm._v("Chat")]), _c('li', [_vm._v("Analytics")]), _c('li', [_vm._v("App Tokens")]), _c('li', [_vm._v("Releases & Comments")]), _c('li', [_vm._v("Sessions & Feedback")]), _c('li', [_vm._v("Public install links")])]), _c('li', [_vm._v("Invites")]), _c('li', [_vm._v("Timeline")]), _c('li', [_vm._v("Members")]), _c('li', [_vm._v("API Tokens")]), _c('li', [_vm._v("Integrations")]), _c('li', [_vm._v("Notifications related this team")])]), _c('br'), _vm._v(" However, your apps and releases data will still be stored in the team, but you won't have access to it. "), _c('br'), _c('br'), _vm._v(" If someone invites you back to the team, you can regain access. ")]) : _vm._e(), !_vm.readMore ? _c('b', {
    staticClass: "pointer",
    on: {
      "click": function click($event) {
        _vm.readMore = !_vm.readMore;
      }
    }
  }, [_vm._v(" " + _vm._s("Read more...") + " ")]) : _vm._e(), _c('br')])], 1), _c('v-divider'), _c('v-card-actions', {
    staticClass: "py-3"
  }, [_c('div', [_vm._v("No undo for this action")]), _c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v("No")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark,
      "loading": _vm.busy
    },
    on: {
      "click": _vm.onConfirm
    }
  }, [_vm._v("Yes")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }