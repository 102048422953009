<template>
  <div>
    <v-card-title>
      <v-spacer />
      <h2 class="blue-text text-center font-weight-bold">
        You've been invited!
      </h2>
      <v-spacer />
    </v-card-title>
    <template v-if="busy">
      <ContentLoader />
    </template>
    <template v-else-if="!busy && !currentInvite">
      <v-card-text>
        <vue-inline-alert messageType="error" :message="message" no-close-btn />
      </v-card-text>
    </template>
    <template v-else>
      <v-card-text class="text-center">
        <v-row>
          <v-col class="text-center">
            <h3 class="mt-8">
              {{
                currentInvite && currentInvite.team
                  ? currentInvite.team.name
                  : ""
              }}
            </h3>
            <div v-if="currentInvite" class="mt-4 ml-4">
              <p class="bodyFont">
                {{
                  `${currentInvite.from_user.name} invited you to join ${currentInvite.team.name}`
                }}
              </p>
            </div>
            <div class="bodyFont" v-if="currentInvite && currentInvite.message">
              With message:
            </div>
            <p class="bodyFont">
              {{ currentInvite.message }}
            </p>
            <p class="text-muted bodyFont">
              <vue-hoverable-date
                :date="currentInvite.created_at"
              ></vue-hoverable-date>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="pb-1 mx-2">
        <v-row>
          <v-col cols="6" class="pb-0">
            <v-btn
              class="large main fill-width"
              :loading="loading2"
              :disabled="loading || loading2"
              :outlined="$vuetify.theme.dark"
              color="primary"
              @click="submitAction('accept')"
            >
              accept
            </v-btn>
          </v-col>
          <v-col cols="6" class="pb-0">
            <v-btn
              class="large main fill-width"
              outlined
              color="error"
              @click="submitAction('decline')"
              :loading="loading"
              :disabled="loading || loading2"
            >
              decline
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </template>
  </div>
</template>

<script>
import { ACCEPT_DECLINE, GET_INVITE } from "@/store/team/invite.module";
import { get } from "@/core/services/helper.service";
import { mapGetters, mapMutations } from "vuex";

export default {
  props: {
    currentInviteDetails: {
      type: Object,
      default() {
        return null;
      },
    },
    inviteId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      loading2: false,
      busy: false,
      message: "",
      currentInvite: null,
    };
  },
  computed: {
    ...mapGetters({
      currentNotifications: "userNotificationsStats",
    }),
    listOfInvites: {
      get: function () {
        let data = get(this.$store.state, "invite.listOfInvites", "");
        return data;
      },
      set: function (v) {
        this.$store.commit("setListOfInvites", v);
      },
    },
  },
  async created() {
    this.busy = true;
    if (this.inviteId) {
      if (this.listOfInvites && this.listOfInvites.length) {
        const details = this.listOfInvites.find(
          (el) => el.id === this.inviteId
        );
        if (details) {
          this.currentInvite = { ...details };
          this.busy = false;
        } else {
          this.currentInvite = null;
          this.busy = false;
        }
      } else {
        this.$store
          .dispatch(GET_INVITE, this.inviteId)
          .then((details) => {
            this.currentInvite = { ...details.data };
            this.busy = false;
          })
          .catch((err) => {
            this.message = err.message || "";
            this.busy = false;
          });
      }
    } else {
      this.currentInvite = this.currentInviteDetails
        ? { ...this.currentInviteDetails }
        : null;
      this.busy = false;
    }
  },
  methods: {
    ...mapMutations({
      updateUserPermission: "updateUserPermission",
    }),
    submitAction(actionType) {
      if (this.loading || this.loading2) return;
      actionType == "accept" ? (this.loading2 = true) : (this.loading = true);
      this.$store
        .dispatch(ACCEPT_DECLINE, {
          id: this.inviteId || this.currentInvite.id,
          actionType,
        })
        .then((response) => {
          // to delete the current list from the list after the action happend
          this.showInviteDrawer = false;
          this.$emit("close");
          if (this.listOfInvites) {
            this.listOfInvites = this.listOfInvites.filter((e) => {
              return e.id !== this.currentInvite.id;
            });
          }
          if (response.permissions) {
            this.updateUserPermission(response.permissions);
          }
          this.notifyUserMessage({ message: response.message });
          let invites = this.currentNotifications.invites - 1;
          if (actionType === "accept") {
            this.$router
              .push({
                name: "dashboard",
              })
              .catch(() => {});
          }
          this.$store.commit("updateNotification", {
            alerts: this.currentNotifications.alerts,
            invites,
          });
          this.$nextTick(() => {
            window.location.reload();
          });
          this.loading = false;
          this.loading2 = false;
        })
        .catch((err) => {
          this.notifyErrorMessage(err.message);
          this.$emit("close");
          this.loading = false;
          this.loading2 = false;
        });
    },
  },
};
</script>
